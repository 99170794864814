.sv_matrix_cell {
  width: 50px; /* Set the desired width */
  min-width: 50px; /* Ensure the minimum width is also set */
}
.app-content {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

h1,
h1+span {
  padding: 0 20px;
}

.jumbotron ul {
  font-size: 21px;
}

.jumbotron {
  background-color: #F3F3F3;
  padding: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.signature {
  font-size: 21px;
  font-style: italic;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
}

.start-survey {
  align-self: center;
  margin-bottom: 30px;
}

.sideBox {
  width: 20%;
  height: 100vh
}

.mainBox {
  width: 60%;
  height: 100vh
}
.credits {
  margin-top: auto;
  text-align: center;
}

.start-survey-btn {
  font-size: large;
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  border: none;
  box-shadow: none;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 5px;
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  border: 2px solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.start-survey-btn:hover {
  opacity: 75%;
  margin: 3px;
  border: 2px solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  padding: calc(1.2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2.7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sd-table__cell > .sd-table__question-wrapper .sd-dropdown__value .sv-string-viewer, .sd-table__cell > .sd-table__question-wrapper .sd-dropdown__value .sd-dropdown__hint-suffix {
       position: absolute;
       left: 0;
       width: 100%;
}

.download-data-btn {
  
  font-size: large;
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  border: none;
  box-shadow: none;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 5px;
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  border: 2px solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.download-data-btn:disabled, .download-data-btn[disabled] {
  background: transparent;
  border: none;
  font-size:0;
}

.uplooad-data-inp:disabled, .uplooad-data-inp[disabled] {
  visibility: hidden;
}

.uplooad-data-inp {
  visibility: visible;
  box-shadow: none;
  padding: calc(0.01 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 5px;
  background-color: var(--sjs-primary-backcolor, var(--primary, #fff));
  border: 2px solid var(--sjs-primary-backcolor, var(--primary, #fff));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #343333));
  padding: var(--sjs-base-unit, var(--base-unit, 4px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.uplooad-data-inp::file-selector-button {
  font-weight: bold;
  color: #fff;
  background-color: #4b4d4c;
  padding: var(--sjs-base-unit, var(--base-unit, 4px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border: none;
  border-radius: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.check-password-btn {
  font-size: small;
  color: var(--sjs-primary-backcolor, var(--primary, #4b4d4c));
  border: none;
  box-shadow: none;
  padding: calc(0.01 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 5px;
  background-color: var(--sjs-primary-backcolor, var(--primary, #4b4d4c));
  border: 2px solid var(--sjs-primary-backcolor, var(--primary, #4b4d4c));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  padding: var(--sjs-base-unit, var(--base-unit, 4px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.password-inp {
  box-shadow: none;
  padding: calc(0.01 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 5px;
  background-color: var(--sjs-primary-backcolor, var(--primary, #fff));
  border: 2px solid var(--sjs-primary-backcolor, var(--primary, #fff));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #343333));
  padding: var(--sjs-base-unit, var(--base-unit, 4px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}