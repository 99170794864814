

.header {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    background-color: white;
    border: 2px solid #BBBBBB;


}

.header img {
    height: 45px;
    margin: 7px 10px 7px 10px;
}

.language-dropdown{
    margin-right: 20px;
    margin-left: auto;
}

#map-container{
    height: 100%;
    width: 100vw;
    align-items: center;
    justify-content: center;
}

#main-map-container{
    height: 80vh;
    align-items: center;
}

#map{
    position: absolute;
    width: 60vw;
    height: 90vh;
    top: 0;
    display: flex;
    justify-content: center;
}

.map-heading{
    position: fixed;
    left: 50%;
    transform: translateX(-50%);

}

.color-box {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 10px;
}

.checkBx {
    display: inline-block;
    margin-right: 10px;
}

.collapsableLegend{
    position: absolute;
    right: 0;
    bottom: 50px;
    border: 5px solid #BBBBBB;
    background-color: white;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
    max-width: 300px;
    min-width: 100px;
}

.icon {
    position: absolute;
    top: 10px; /* Distance from the top */
    right: 10px; /* Distance from the right */
    width: 24px; /* Width of the icon */
    height: 24px; /* Height of the icon */
    cursor: pointer; /* Change cursor on hover */
}

.dropdown{
    max-width: 400px;
    align-items: center;
}

.infoBox{
    position: absolute;
    padding: 10px;
    margin: 10px;
    top: 20%;
    left: 0;
    border: 5px solid blue;
    background-color: white;
    border-radius: 10px;
    width: fit-content;
    max-width: 300px;
}

.spaceHolder {
    margin-top: 20px;
    margin-bottom: 20px;
}

.formalIcon {
    width: 10px;
    height: 15px;
    border-bottom: 2px solid dimgrey;
}
.informalIcon {
    width: 10px;
    height: 15px;
    border-bottom: 2px dashed dimgrey;
}

.size-icon{
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 10px;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 20px;
}


.filter-legend-container{
    display: grid;
    grid-template: auto 1fr / auto 1fr ;
}

.infobox{
    display: grid;
    grid-template: auto 1fr / auto 1fr ;
}

.navbar-toggler{
    border: none;
    margin-left: 20px;
    margin-right: auto
}

.navbar{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.network--metrics{
    display: flex;
    position: fixed;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: auto;
    justify-content:center
}
.network--metrics p{
    margin: 5px;
}

.infobox-container h2{
    font-size: 24px;
}

